<template>
  <div id="users-list">
    <users-list />
  </div>
</template>

<script>
import UsersList from "@/views/apps/user/users-list/UsersList.vue";

export default {
  name: "Users",
  components: {
    UsersList,
  },
};
</script>
